<template>
    <v-form>
  <v-card class="pa-2 card flat-card" color="surface">
      <v-row dir="ltr" class="pa-2 mb-2 d-flex justify-start align-center">
        <rating class="mt-2" @Rate_set = 'Rate_set' :config = 'config'/>
        <span class="smallFont ml-auto mr-1 onSurface--text">:به این محصول امتیاز بدهید</span>
      </v-row>
      <v-row>
      </v-row>
     <v-textarea v-model="comment" name="text" label="متن نظر*"></v-textarea>
     <v-card-actions>
       <v-spacer></v-spacer>
     <v-btn @click="sub_comment()" class="primaryActions onPrimaryActions--text button">ثبت نظر</v-btn>
     </v-card-actions>
  </v-card>
    </v-form>
</template>
<script>
import rating from '../cards/rate'
import { add_comment } from '../../models/productsDetail'
export default {
  props: ['productID'],
  components: {
    rating
  },
  data:() => ({
    comment: '',
    rate: 0,
    config: {
      width: 25,
      increment: 1,
      rounded:true,
      showRating:false
    }
  }),
  methods: {
    Rate_set (Rate) {
      this.rate = Rate
    },
    sub_comment () {
      add_comment({
        product_id: this.$props.productID,
        text: this.comment,
        rate: this.rate
      })
    }
  }
}
</script>