<template>
    <v-container>
        <navbar title="ثبت نظر"/>
        <v-main class="pt-15">
            <error-alert />
            <success-alert />
            <loading-page v-if="loading" />
            <comment :productID = 'productID' />
        </v-main>
    </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import comment from '../productDetail/components/forms/addComment'
import LoadingPage from '@/components/tools/loadingPage.vue'
import { mapState } from 'vuex'
import ErrorAlert from '@/components/alerts/error.alert.vue'
import SuccessAlert from '@/components/alerts/success.alert.vue'
export default {
    props: ['productID'],
   components: {
       navbar,
       comment,
      LoadingPage,
      ErrorAlert,
      SuccessAlert
   },
   computed: {
    ...mapState({
      loading: state => state.loading_page
    })
   }
}
</script>